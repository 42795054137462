<div class="d-flex flex-row w-100 h-100">
    <div class="d-flex align-items-center" 
        [class.text-success]="this.data.type=='success'"
        [class.text-info]="this.data.type=='info'"
        [class.text-warning]="this.data.type=='warning'"
        [class.text-danger]="this.data.type=='danger'"
        style="font-size: 1.5em;">
        <i class="fas fa-check-circle" *ngIf="this.data.type=='success'"></i>
        <i class="fas fa-exclamation-triangle" *ngIf="this.data.type=='warning'"></i>
        <i class="fas fa-info-circle" *ngIf="this.data.type=='info'"></i>
        <i class="fas fa-radiation-alt" *ngIf="this.data.type=='danger'"></i>
    </div>
    <div class="d-flex align-items-center text-wrap text-dark text-justify" style="font-size: 1em; margin-left: 16px;">  {{this.data.message}} </div>
</div>