import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-blank-confirm',
  templateUrl: './blank-confirm.component.html',
  styleUrls: ['./blank-confirm.component.scss']
})
export class BlankConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BlankConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {  }

  ngOnInit() { }

  ok(){
    this.dialogRef.close({confirm:true});
  }

}
