import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modal: MatDialog
  ) { }

  public openModal(component: any, sizeWidth = 'auto', sizeHeight = 'auto', data= {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = sizeWidth;
    dialogConfig.height = sizeHeight;
    dialogConfig.minHeight = sizeHeight;
    dialogConfig.minWidth = sizeWidth;
    dialogConfig.data = data;
    dialogConfig.panelClass = "custom-dialog-container";

    return this.modal.open(component, dialogConfig);
  }

  public openModalWithoutBg(component: any, sizeWidth = 'auto', sizeHeight = 'auto', data= {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = sizeWidth;
    dialogConfig.height = sizeHeight;
    dialogConfig.minHeight = sizeHeight;
    dialogConfig.minWidth = sizeWidth;
    dialogConfig.data = data;
    dialogConfig.panelClass = "custom-dialog-container-without-bg";

    return this.modal.open(component, dialogConfig);
  }
}
