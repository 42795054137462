<div class="d-flex flex-column position-relative w-100 h-100">
    <div class="d-flex flex-row w-100 align-items-center bg-light" style="min-height: 60px; max-height: 60px;">
        <div class="d-flex flex-row flex-fill text-dark" style="padding-left: 16px;">
            <div style="font-size: 1.3em; padding-left: 16px;">Mon profil</div>
            <div style="padding-left: 16px;">/</div>
            <div style="padding-left: 16px;">
                <span *ngIf="this.is_edit">Modification</span>
            </div>
        </div>
        <div style="padding-right: 16px;">
            <ng-template [ngIf]="!this.is_edit" [ngIfElse]="edit_actions">
                <button mat-button type="submit" *ngIf="!this.is_edit" (click)="launch_edit()" style="border: 0.5px dotted grey; color: grey;"><i class="fas fa-pen"></i></button>
            </ng-template>
            <ng-template #edit_actions>
                <button mat-button type="submit" *ngIf="this.is_edit" (click)="save_edit()" style="border: 0.5px dotted grey; color: grey;" [disabled]="this.formUtilisateur.invalid">Enregistrer</button>
                <button mat-button type="submit" *ngIf="this.is_edit" (click)="this.is_edit=false" style="margin-left: 16px; border: 0.5px dotted grey; color: grey;">Annuler</button>
            </ng-template>
        </div>
        <button mat-dialog-close class="btn btn-close" style="margin-right: 16px;"></button>
    </div>
    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="this.is_loading"></mat-progress-bar>
    </div>
    <div class="d-flex flex-row flex-fill p-4 w-100" style="overflow-y: auto;">
        <ng-template [ngIf]="this.is_edit" [ngIfElse]="apercu">
            <form [formGroup]="this.formUtilisateur" class="w-100">
                <div class="d-flex flex-row w-100">
                    <div class="d-flex flex-column w-50" style="padding-right: 16px; padding-bottom: 16px;">
                        <mat-form-field>
                            <mat-label>Adresse mail</mat-label>
                            <input matInput type="text" placeholder="inserer l'adresse mail de l'utilisateur" formControlName="email" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select formControlName="role" disabled>
                                <mat-option *ngFor="let row of this.roles" [value]="row.id">{{row.nom}}</mat-option>
                            </mat-select>
                            <mat-label>Role</mat-label>
                        </mat-form-field>
                        <div class="pb-1 text-secondary text-wrap" style="margin-top: 14px; margin-bottom: 14px;">
                            <mat-checkbox class="pr-4 text-wrap" color="primary" formControlName="is_edit_pwd">Modifier le mot de passe</mat-checkbox>
                        </div>
                        <mat-form-field *ngIf="this.formUtilisateur.controls.is_edit_pwd.value">
                            <mat-label>{{(this.current_action=='new')?'Mot de passe':'Nouveau mot de passe'}}</mat-label>
                            <input matInput [type]="pwd_hidden?'password':'text'" formControlName="password" (keyup)="this.controler_pwd()" style="font-size: 1.1em; letter-spacing: 0.1em;">
                            <span matSuffix (click)="pwd_hidden=!pwd_hidden" class="pwd_hidden"><i class="fas" [class.fa-eye]="pwd_hidden" [class.fa-eye-slash]="!pwd_hidden"></i></span>
                        </mat-form-field>
                        <mat-form-field *ngIf="this.formUtilisateur.controls.is_edit_pwd.value">
                            <mat-label>{{(this.current_action=='new')?'Confirmer votre mot de passe':'Confirmer votre nouveau mot de passe'}}</mat-label>
                            <input matInput type="password" formControlName="confirm_password" (keyup)="this.controler_pwd()" style="font-size: 1.1em; letter-spacing: 0.1em;">
                            <span matSuffix class="text-success" *ngIf="same_pwd"><i class="fas fa-check-circle"></i></span>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Nom</mat-label>
                            <input matInput type="text" placeholder="inserer le nom de l'utilisateur" formControlName="nom" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Prenoms</mat-label>
                            <input matInput type="text" placeholder="inserer les prenoms de l'utilisateur" formControlName="prenoms">
                        </mat-form-field>
                    </div>
                    <div class="d-flex flex-column w-50" style="margin-left: 16px;">
                        <mat-form-field>
                            <input matInput [matDatepicker]="dn" placeholder="Date de naissance de l'utilisateur" formControlName="dateNaissance" required>
                            <mat-datepicker-toggle matSuffix [for]="dn"></mat-datepicker-toggle>
                            <mat-datepicker #dn></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select formControlName="sexe" required>
                                <mat-option *ngFor="let row of this.sexes" [value]="row.id">{{row.nom}}</mat-option>
                            </mat-select>
                            <mat-label>Sexe</mat-label>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>CIN (Carte d'Identite Nationale)</mat-label>
                            <input matInput type="text" placeholder="inserer le CIN de l'utilisateur" formControlName="cin" required>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Adresse</mat-label>
                            <textarea matInput placeholder="inserer l'adresse de l'utilisateur" formControlName="adresse"></textarea>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Numero telephone</mat-label>
                            <input matInput type="text" placeholder="inserer le numero telephone de l'utilisateur" formControlName="numeroTel" required>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </ng-template>
        <ng-template #apercu>
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column w-50" style="padding-right: 16px;">
                    <app-input label="Adresse mail" [value]="this.data.email"></app-input>
                    <app-input label="Role" [value]="this.data.role?.nom"></app-input>
                    <app-input label="Nom" [value]="this.data.nom"></app-input>
                    <app-input label="Prenoms" [value]="this.data.prenoms"></app-input>
                </div>
                <div class="d-flex flex-column w-50" style="margin-left: 16px;">
                    <app-input label="Date de naissance" value="{{this.data.dateNaissance | date: 'dd/MM/yyyy'}}"></app-input>
                    <app-input label="Sexe" value="{{this.data.sexe?.nom}}"></app-input>
                    <app-input label="CIN (Carte d'Identite Nationale)" value="{{this.data.cin}}"></app-input>
                    <app-input-area label="Adresse" value="{{this.data.adresse}}"></app-input-area>
                    <app-input label="Numero telephone" value="{{this.data.numeroTel}}"></app-input>
                </div>
            </div>
        </ng-template>
   </div>
</div>