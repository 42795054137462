import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {
  private myappthos:string[] = ['','mille','millions', 'milliards','mille milliards'];
  private myappdang:string[] = ['zero','un','deux','trois','quatre', 'cinq','six','sept','huit','neuf']; 
  private myapptenth:string[] = ['dix','onze','douze','treize', 'quatorze','quinze','seize', 'dix-sept','dix-huit','dix-neuf'];
  private myapptvew:string[] = ['vingt','trente','quarente','cinquante', 'soixante','soixante-dix','quatre-vingt','quatre-vingt-dix']; 

  transform(value: number): any {
    if (value && this.isInteger(value)) {
      return  this.convertWords(value);
    }
  }
  
  isInteger(value : number) : boolean  {
    return value % 1 === 0;
  }
  convertWords(s : number) : string {
    var s_toString : string;
    s_toString = s.toString().replace(/[\, ]/g,'');
    if (s != parseFloat(s_toString)) return 'not a number';
    var query = s_toString.indexOf('.'); 
    if (query == -1) query = s_toString.length;
    if (query > 15) return 'too big';
    var n:any[] = s_toString.split(''); 

    var str : string = '';
    var mjk : number = 0;

    for (var Id:number = 0; Id < query; Id++) {
      if ((query-Id)%3==2){
        if (n[Id] == '1'){
          str += this.myapptenth[Number(n[Id+1])] + ' ';
          Id++; 
          mjk=1;
        }else if(n[Id]!='0'){
          str += this.myapptvew[Number(n[Id])-2] + ' ';
          mjk=1;
        }
      }else if (n[Id]!='0'){
        str += this.myappdang[n[Id]] +' '; 
        if ((query-Id)%3==0) str += 'cent ';
        mjk=1;
      }
      if ((query-Id)%3==1) {
        if (mjk) str += this.myappthos[(query-Id-1)/3] + ' ';
        mjk=0;
      }
    }
    if (query != s_toString.length) {
        var dv = s_toString.length;
        str += 'point ';
        for (var ld=query+1; ld<dv; ld++){
          str += this.myappdang[n[ld]] +' ';
        }
    }
    return str.replace(/\s+/g,' ');
  }

}
