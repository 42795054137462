import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Global } from '../models/global.model';
import { MonProfilComponent } from '../pages/mon-profil/mon-profil.component';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public logo_path: string = 'assets/logo-white.png';
  public is_loading: boolean = false;
  public side_is_mini:boolean = false;
  public mini_width: any = "";
  public normal_width: any = "";
  public user_info: any = {};
  public current_date = new Date();
  public menus:any[] = [
    {
      title: 'Tableau de bord',
      icon: 'bar-chart',
      path: 'dashboard',
      access_grant: 'ALL'
    },
    {
      title: 'Clients',
      icon: 'people-circle',
      path: 'clients',
      access_grant: 'ALL'
    },
    {
      title: 'Voitures',
      icon: 'car',
      path: 'voitures',
      access_grant: 'ALL'
    },
    {
      title: 'Ventes',
      icon: 'cart',
      path: 'ventes',
      access_grant: 'ALL'
    },
    {
      title: 'Paiements',
      icon: 'card',
      path: 'paiements',
      access_grant: 'ALL'
    },
    {
      title: 'Parametrages',
      icon: 'cog',
      path: 'parametrages',
      access_grant: 'ADMIN'
    }
  ];

  constructor(
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private global: Global,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mini_width = this.sanitizer.bypassSecurityTrustStyle("calc(100vw - 70px)");
    this.normal_width = this.sanitizer.bypassSecurityTrustStyle("calc(100vw - 230px)");
    this.authService.getUser().subscribe((user)=>{
      this.user_info = user;
      if(user) this.menus = this.menus.filter(menu=> ((user.role.title).localeCompare(menu.access_grant)==0 || (menu.access_grant).localeCompare('ALL')==0));
    })
    this.global.getLoading().subscribe((value)=>{
      this.is_loading=value;
      this.cdRef.detectChanges();
    });
  }

  go_to_account() {
    this.modalService.openModal(MonProfilComponent,'60%','70%',JSON.parse(localStorage.getItem('user_logged'))).afterClosed().subscribe((data:any)=>{
      this.user_info = JSON.parse(localStorage.getItem('user_logged'));
    })
  }

  logout() {
    this.authService.logout();
  }

  reduire() {
    this.side_is_mini=!this.side_is_mini;
  }

}
