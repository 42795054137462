import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';

@Component({
  selector: 'app-recu-paiement',
  templateUrl: './recu-paiement.component.html',
  styleUrls: ['./recu-paiement.component.scss']
})
export class RecuPaiementComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }

  print() {
    // var element = document.getElementById('facture_standard');
    // html2canvas(element!).then((canvas)=>{
    //   console.log(canvas);
    //   let img_data = canvas.toDataURL('image/png');
    //   let doc = new jspdf.jsPDF('p','mm', 'a4');
    //   doc.addImage(img_data, 0,0,208,(canvas.height*208/canvas.width));
    //   doc.save('facture.pdf');
    // })
  }

}
