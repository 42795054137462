import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { Storage } from '@capacitor/storage';
const TOKEN_KEY = 'user-token';

export interface User {
  username: string;
  role: string;
  permissions: string[];
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  public formAuth: FormGroup  = this.formBuilder.group({});
  public profile_is_loading: boolean = false;

  constructor(
    protected router:Router,
    protected http: HttpClient,
    protected formBuilder: FormBuilder
  ) {
    this.loadUser();
  }

  loadUser() {
    let res: any = localStorage.getItem('user_logged');
    if(res) this.currentUser.next(JSON.parse(res));
    else this.currentUser.next(false);
  }

  getUser() {
    this.loadUser();
    return this.currentUser.asObservable();
  }

  login(email:string, password:string) {
    this.profile_is_loading = true;
    return this.http.post<any>(`${environment.apiUrl}/login`, {email, password})
    .pipe(
      map((user:any) => {
        // login successful if there's a jwt token in the response
        if(user && user.token) {
          localStorage.setItem('token', JSON.stringify(user.token));
          this.http.get<any>(`${environment.apiUrl}/me`).subscribe((data: any)=>{
             localStorage.setItem('user_logged', JSON.stringify(data));
             this.router.navigate(['/dashboard']);
             this.profile_is_loading = false;
          });
        }
        return user;
    }))
  }

  refresh_info_user() {
    return this.http.get<any>(`${environment.apiUrl}/me`);
  }

  async logout() {
    //remove user from local storage to log user out
    this.currentUser.next(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user_logged');
    this.router.navigate(['connexion']);
  }

  formAuthControl(){
    this.formAuth = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]],
      password:["", Validators.required]
    });
  }

  hasPermission(permissions: string[]): boolean {
    for (const permission of permissions) {
      if (!this.currentUser.value || !this.currentUser.value.permissions.includes(permission)) {
        return false;
      }
    }
    return true;
  }
}
