<div id="connexion" class="w-100 h-100" [style.background-image]="'url('+this.bg_path+')'" style="background-repeat: no-repeat; background-size: cover; -webkit-filter: hue-rotate(180deg);filter: hue-rotate(180deg);">
    <div class="d-flex align-items-center justify-content-center w-100 h-100" style="background-color: rgba(255, 255, 255, 0.733);">
        <div class="d-flex flex-column bg-white" style="width: 300px; height: auto;border-radius: 8px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
        <div class="d-flex align-items-center justify-content-center w-100" style="height: 40px; border-top-left-radius: 8px; border-top-right-radius: 8px;">
            <!-- <div class="d-flex align-items-center justify-content-center text-white" style="background-color: #414042; width: 120px; height: 120px; border-radius: 1000px; margin-top: -15%; font-size: 6em; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"><i class="fas fa-user-circle"></i></div> -->
            <div class="d-flex align-items center justify-content-center" style="margin-top: -20%;">
                <img [src]="this.logo_path" width="180px" alt="">
            </div>
        </div>
        <div class="d-flex flex-column flex-fill w-100 p-4" style="border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">
            <form [formGroup]="this.formAuth">
                <mat-form-field>
                    <mat-label>Adresse mail</mat-label>
                    <input matInput placeholder="" type="text" style="font-size: 0.9em !important; letter-spacing: 0.065em;" formControlName="email">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Mot de passe</mat-label>
                    <input matInput placeholder="" [type]="hide?'password':'text'" style="font-size: 0.9em !important; letter-spacing: 0.1em;" formControlName="password">
                    <span matSuffix (click)="hide=!hide" class="hide"><i class="fas" [class.fa-eye]="hide" [class.fa-eye-slash]="!hide"></i></span>
                    <mat-hint class="text-danger" *ngIf="this.is_wrong_password && formAuth.controls.password.value!=''">Mot de passe incorrect.</mat-hint>
                </mat-form-field>
                <button matRipple [matRippleCentered]="true" class="btn btn-sm btn-dark w-100 mt-3 button" [class.button--loading]="connexion_is_loading" type="submit" (click)="signIn()" [disabled]="formAuth.invalid || connexion_is_loading" >
                    <!-- <span class="button__text">Connexion</span> -->
                    <ng-template [ngIf]="!this.profile_is_loading" [ngIfElse]="loaded">Connexion</ng-template>
                    <ng-template #loaded>
                        <app-loading></app-loading>
                    </ng-template>
                </button>
            </form>
        </div>
    </div>
    </div>
</div>