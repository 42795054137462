import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent extends AuthService implements OnInit {

  public hide:boolean = true;
  public connexion_is_loading:boolean = false;
  public is_wrong_password:boolean = false;
  public logo_path: string = 'assets/logo-dark.png';
  public bg_path: string = 'assets/blue_daf_xf105_truck-wallpaper-1366x768.jpg';

  constructor(
    protected router:Router,
    protected http: HttpClient,
    protected formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer
  ) { 
    super(router, http, formBuilder);
  }

  ngOnInit(): void {
    this.formAuthControl();
  }

  ngAfterViewInit() {
    this.formAuth.valueChanges.subscribe((change:any)=>{
      if(change.password.localeCompare('')==0) {
        this.is_wrong_password = false;
      }
    })
  }

  signIn() {
    this.connexion_is_loading = true;
    this.login(this.formAuth.value['email'], this.formAuth.value['password']).pipe(
      catchError(err=> {
        if(err.status==401) {
          this.is_wrong_password = true;
        } else if(err.status==500) {
          this.is_wrong_password = false;
          this.notificationService.danger("Une erreur a survenu lors de l'authentification.");
        }
        this.connexion_is_loading = false;
        this.profile_is_loading = false;
        return throwError(err);
      })
    ).subscribe(
      (data:any)=>{
        this.connexion_is_loading = false;
        this.is_wrong_password = false;
      }
    )
  }

}
