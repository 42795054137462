import { BehaviorSubject } from "rxjs";

export class Global {
    private is_loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getLoading() {
        return this.is_loading.asObservable();
    }
    set_is_loading(value: boolean) {
        this.is_loading.next(value);
    }
}