import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../layouts/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    protected _snackBar: MatSnackBar
  ) { }

  success(message: string) {
    this._snackBar.openFromComponent(NotificationComponent, {
      data: {
        type:'success',
        message: message
      },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['success-snackbar'],
      duration: 5000
    });
  }

  info(message: string) {
    this._snackBar.openFromComponent(NotificationComponent, {
      data: {
        type:'info',
        message: message
      },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['info-snackbar'],
      duration: 5000
    });
  }

  warning(message: string) {
    this._snackBar.openFromComponent(NotificationComponent, {
      data: {
        type:'warning',
        message: message
      },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['warning-snackbar'],
      duration: 5000
    });
  }

  danger(message: string) {
    this._snackBar.openFromComponent(NotificationComponent, {
      data: {
        type:'danger',
        message: message
      },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['danger-snackbar'],
      duration: 5000
    });
  }
}
