import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss']
})
export class InputAreaComponent implements OnInit {

  @Input('label') label: string = '';
  @Input('value') value: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
