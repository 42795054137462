<div class="d-flex flex-column h-auto">
    <div class="flex-fill position-fixed" style="width:40%;min-height: 30px;z-index: 99; margin-top: -40px">
        <div class="d-flex flex-row h-100">
            <div class="flex-fill">
            </div>
            <div mat-dialog-close matRipple [matRippleCentered]="true" class="justify-content-center btn-close align-items-center button-close bg-white" style="width: 30px; height:30px; text-align: center;">
                <div class="d-flex align-items-center justify-content-center w-100 h-100">
                    <!-- <i class="fas fa-times"></i> -->
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-fill w-100 h-auto bg-white p-3">
        <div class="d-flex flex-column align-items-center justify-content-center text-justify p-3 h-75 text-secondary">
            <div class="w-100 h-auto text-justify" style="font-size: 1.1em;">
                {{this.data.message}}
            </div>
            <div class="d-flex flex-row h-auto mt-2 w-100">
                <div matRipple [matRippleCentered]="true" class="d-flex align-items-center justify-content-center p-2 bg-light flex-fill my-button" (click)="annuler()" style="margin-right: 8px;">
                    Non, annuler l'action.
                </div> 
                <div matRipple [matRippleCentered]="true" class="d-flex align-items-center justify-content-center p-2 bg-light flex-fill my-button" (click)="confirmer()" style="margin-left: 8px;">
                    Oui, je confirme.
                </div> 
            </div>
        </div>
    </div>
</div>