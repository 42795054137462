import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    protected http: HttpClient
  ) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}/roles`);
  }
}
