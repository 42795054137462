import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  public formUtilisateur: FormGroup = this.formBuilder.group({});

  constructor(
    protected http: HttpClient,
    protected formBuilder: FormBuilder
  ) { }

  search(value:string) {
    let results:any[]=[];
    this.http.get<any[]>(`${environment.apiUrl}/users?nom=${value}&prenoms=${value}`).subscribe((data:any)=>{
      results = data["hydra:member"];
    })
    return results;
  }

  create(user:any) {
    return this.http.post<any[]>(`${environment.apiUrl}/users`, user);
  }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}/users`);
  }

  getById(id:number) {
    return this.http.get<any>(`${environment.apiUrl}/users/${id}`);
  }

  update(id:number, user:any) {
    return this.http.put<any>(`${environment.apiUrl}/users/${id}`, user);
  }

  delete(id:number) {
    return this.http.delete<any>(`${environment.apiUrl}/users/${id}`);
  }

  formUtilisateurControl(data : any = null){
    if (data == null) {
      this.formUtilisateur = this.formBuilder.group({
        email: ["", Validators.required],
        nom: ["", Validators.required],
        prenoms:[""],
        is_edit_pwd: [true],
        password: [""],
        confirm_password: [""],
        adresse:[""],
        sexe:["", Validators.required],
        cin:["", Validators.required],
        numeroTel:["", Validators.required],
        dateNaissance:["", Validators.required],
        role: ["", Validators.required]
        });
    } else {
      this.formUtilisateur = this.formBuilder.group({
        email: [data.email, Validators.required],
        nom: [data.nom, Validators.required],
        prenoms:[data.prenoms],
        is_edit_pwd: [false],
        password: [""],
        confirm_password: [""],
        adresse:[data.adresse],
        sexe:[data.sexe.id, Validators.required],
        cin:[data.cin, Validators.required],
        numeroTel:[data.numeroTel, Validators.required],
        dateNaissance:[data.dateNaissance, Validators.required],
        role: [data.role.id, Validators.required]
      });
    }
  }

  checkPasswords() {
    if((this.formUtilisateur.get('password')?.value!=="") || (this.formUtilisateur.get('confirm_password')?.value!=="")){
      return (this.formUtilisateur.get('password')?.value === this.formUtilisateur.get('confirm_password')?.value)?true:false;
    }else {
      return false;
    }
  }
}
