import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RoleService } from 'src/app/services/role.service';
import { SexeService } from 'src/app/services/sexe.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-mon-profil',
  templateUrl: './mon-profil.component.html',
  styleUrls: ['./mon-profil.component.scss']
})
export class MonProfilComponent extends UtilisateurService implements OnInit {

  public is_edit: boolean = false;
  public is_loading: boolean = false;
  public roles: any[] = [];
  public sexes: any[] = [];

  constructor(
    protected http: HttpClient,
    protected formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleService: RoleService,
    private sexeService: SexeService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) { 
    super(http, formBuilder);
  }

  ngOnInit(): void { 
    this.roleService.getAll().subscribe((data)=>{this.roles = data});
    this.sexeService.getAll().subscribe((data)=>{this.sexes = data});
    this.formUtilisateurControl(this.data);
  }

  launch_edit() {
    this.is_edit = true;
  }

  save_edit() {
    this.is_loading = true;
    this.update(this.data.id, {...this.formUtilisateur.value, sexe: '/api/sexes/'+this.formUtilisateur.value['sexe'], role: '/api/roles/'+this.formUtilisateur.value['role']}).subscribe((data)=>{
      this.is_loading = false;
      this.notificationService.success('Modification de l\'utilisateur effectuee avec succes.');
        localStorage.setItem('user_logged', JSON.stringify(data));
        this.data = data;
      this.is_edit = false;
    })
  }
}
