import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor( 
        protected authentificationService: AuthService,
        protected router: Router, 
        protected notificationService: NotificationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err=> {
            if([401, 403].indexOf(err.status) !== -1) {
                //auto logout if 401 Unauthorized or 403 Forbidden response from api
                if((err.error.message.split(" ")[0]).localeCompare("Expired")==0) {
                    this.notificationService.warning("Votre session a expirée. Veuiilez vous reconnecter!");
                }
                this.authentificationService.logout();
                this.router.navigate(['/connexion']);
            }
            // const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}