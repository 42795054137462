<div class="d-flex flex-column position-relative w-100 h-100">
    <div class="d-flex flex-row w-100 align-items-center bg-light" style="min-height: 60px; max-height: 60px;">
        <div class="d-flex flex-row flex-fill text-dark" style="padding-left: 16px;">
            <div style="font-size: 1.3em; padding-left: 16px;">Recu du paiement</div>
            <div style="padding-left: 16px;">/</div>
            <div style="padding-left: 16px;">
                <span>Apercu</span>
            </div>
        </div>
        <div style="padding-right: 16px;">
            <button mat-button type="button" (click)="print()" style="border: 0.5px dotted grey; color: grey;"><i class="fas fa-print text-seondary"></i></button>
        </div>
        <button mat-dialog-close class="btn btn-close" style="margin-right: 16px;"></button>
    </div>
    <div id="facture_standard" class="d-flex flex-column w-100 h-100" style="padding: 48px; overflow-y: auto;">
        <div class="d-flex justify-content-between align-items-start en-tete w-100 pb-3" style="border-bottom: 2px solid black;">
            <div class="d-flex flex-column" style="color: black; font-family: Arial, Helvetica, sans-serif;">
                <div class="mb-2 pb-1" style="font-weight: bold; font-size: 1.5em;">DAGO TRUCK SARL.</div>
                <div class="desc_clinic">Vente vehicule occasion</div>
                <div class="desc_clinic">0342074791 / 0343284198 </div>
                <div class="desc_clinic">Sahalava, Fianarantsoa</div>
            </div>
            <div class="d-flex flex-column" style="color: black;">
                <div class="mb-1 pb-1" style="font-weight: bold; font-family: Arial, Helvetica, sans-serif;">Doit à</div>
                <div>{{this.data.client.nom | uppercase}} {{this.data.client.prenoms}}</div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center w-100 mt-4 mb-4" style="color: black; font-family: Arial, Helvetica, sans-serif;">Fianarantsoa, le {{this.data.date | date:"d MMMM y"}}</div>
        <div class="d-flex justify-content-center w-100 p-3" style="font-size: 3em; font-weight: bold; color: black; font-family: Arial, Helvetica, sans-serif;">RECU</div>
        <div class="d-flex flex-column w-100 pt-4 pb-2" style="color: black; font-family: Arial, Helvetica, sans-serif;">
            <div class="mt-3">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">Designation</th>
                        <th scope="col">Montant payé (FMG)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Paiement tranche</td>
                            <td>{{(data?.montant).toFixed(2) | number:'':'fr-FR'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="d-flex justify-content-start align-items-center w-100 mt-4 mb-4" style="color: black; font-family: Arial, Helvetica, sans-serif;">
            Arreté à la somme de <span class="font-weight-bold ml-3" style="font-size: 1.1em; margin-left: 16px;"> <b> {{data?.montant}} Ariary</b></span>.
        </div>
    </div>
</div>