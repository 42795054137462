import { LoadingComponent } from './layouts/loading/loading.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { environment } from 'src/environments/environment';
import {MatStepperModule} from '@angular/material/stepper';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { MainComponent } from './main/main.component';
import { IonicModule } from '@ionic/angular';
import { JwtInterceptor } from './helpers/jwtInterceptor.helper';
import { NotificationComponent } from './layouts/notification/notification.component';
import { ErrorInterceptor } from './helpers/errorInterceptor.helper';
import { ResponseInterceptor } from './helpers/responseInterceptor.helper';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { ConfirmComponent } from './layouts/confirm/confirm.component';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { RecuPaiementComponent } from './pages/paiements/recu-paiement/recu-paiement.component';
import { BlankConfirmComponent } from './layouts/blank-confirm/blank-confirm.component';
import { InputAreaComponent } from './layouts/input-area/input-area.component';
import { InputComponent } from './layouts/input/input.component';
import { LoadingDarkComponent } from './layouts/loading-dark/loading-dark.component';
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    // InputComponent,
    // InputAreaComponent,
    ConnexionComponent,
    MainComponent,
    NotificationComponent,
    ConfirmComponent,
    LoadingComponent,
    // LoadingDarkComponent,
    BlankConfirmComponent,
    NotFoundComponent,
    RecuPaiementComponent,
    NumberToWordsPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    MatPseudoCheckboxModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTabsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDividerModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    MatRippleModule,
    MatExpansionModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    ScrollingModule
  ],
  exports : [
    NumberToWordsPipe,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTabsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDividerModule,
    MatRippleModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatExpansionModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSnackBarModule
  ],
  providers: [
    DatePipe,
    NumberToWordsPipe,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {provide: MAT_DATE_LOCALE, useValue: 'mg'},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_DATE_FORMATS, useValue: environment}
  ],
  entryComponents: []
})
export class SharedModule { }
