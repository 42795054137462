<div class="d-flex flex-row w-100 h-100">
    <div class="d-flex flex-column h-100 position-fixed" [style.width.px]="this.side_is_mini?70:230" style="background-color: #414042; z-index: 99;">
        <div class="d-flex align-items-center justify-content-center h-25 w-100 text-white" style="font-family: 'CollegiateFLF'; font-size: 3em;">
            <!-- <i class="fab fa-angrycreative" [style.fontSize.em]="this.side_is_mini?1:3" [style.marginTop.px]="this.side_is_mini?'-105':'0'"></i> -->
            <img [src]="this.logo_path" [width]="this.side_is_mini?48:110" [style.marginTop]="this.side_is_mini?'-125%':'0'" alt="">
        </div>
        <div class="d-flex flex-column flex-fill navigation">
            <ul>
                <li *ngFor="let menu of this.menus" class="list" routerLinkActive="active" matTooltip="{{menu.title | uppercase}}" matTooltipPosition="after" [matTooltipDisabled]="!this.side_is_mini">
                    <b></b>
                    <b></b>
                    <a [routerLink]="[ menu.path ]">
                        <!-- <span class="icon"><i class="fas" [ngClass]="'fa-'+menu.icon"></i></span> -->
                        <ion-icon class="icon" [name]="menu.icon+'-outline'"></ion-icon>
                        <span *ngIf="!this.side_is_mini" class="title">{{menu.title}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="d-flex flex-column flex-fill" [style.marginLeft.px]="this.side_is_mini?70:230" [style.width]="this.side_is_mini?mini_width:normal_width">
        <div class="d-flex justify-content-between align-items-center position-fixed bg-light" [style.width]="this.side_is_mini?mini_width:normal_width" style="height: 60px; border-bottom: 3px solid white;">
            <div style="padding-left: 12px;">
                <button mat-icon-button color="secondary" (click)="this.reduire()"><i class="fas" [class]="!this.side_is_mini?'fa-angle-left':'fa-angle-right'"></i></button>
            </div>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column align-items-center" style="line-height: 13px; color: #414042;">
                    <div class="d-flex align-items-end justify-content-end flex-fill" style="font-weight: 700; font-size: 0.9em;">{{this.user_info.nom | uppercase}} {{this.user_info.prenoms}}</div>
                    <div class="d-flex justify-content-end align-items-start pt-0 w-100 flex-fill"><i style="font-size: 0.8em;">{{this.user_info.role.nom}}</i></div>
                </div>
                <div class="">
                    <button mat-icon-button [matMenuTriggerFor]="menu" color="dark">
                        <span class="fas fa-ellipsis-v" style="font-size: 1.3em;"></span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item class="text-secondary" (click)="go_to_account()">
                            <mat-label style="margin-right: 10px; font-size: 1.1em;"><i class="fas fa-user-circle text-secondary"></i></mat-label>
                            <span style="font-size: 0.9em; font-weight: bold; margin-right: 6px; font-family: 'Consolas' !important;">Mon Profil</span>
                        </button>
                        <button mat-menu-item class="text-secondary" (click)="logout()">
                            <mat-label style="margin-right: 10px; font-size: 1.1em;"><i class="fas fa-power-off text-secondary"></i></mat-label>
                            <span style="font-size: 0.9em; font-weight: bold; margin-right: 6px; font-family: 'Consolas' !important;">Deconnexion</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <mat-progress-bar mode="query" color="purple" *ngIf="this.is_loading"></mat-progress-bar>
        <div class="d-flex flex-column flex-fill bg-white p-3" style="margin-top: 60px; text-align: justify; overflow-y: auto;">
            <div class="p-4">
                <router-outlet></router-outlet>
            </div>
            <div id="footer" class="d-flex justify-content-end w-100 text-secondary" style="font-size: 0.7em; padding-right: 24px;">
                <div class="d-flex flex-column" style="text-align: right;">
                    <div><i class="fab fa-github"></i> lkrx1/dago_truck - {{this.current_date | date:'YYYY'}}</div>
                </div>
            </div>
        </div>
    </div>
</div>